<template>
  <div>
    <v-app-bar id="sub-bar" dense elevation="2">
      <v-btn text @click="showMenu" class="ml-n6">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <div id="lbl-screen-name">{{ $t(tittle) }}</div>
      <!-- <div id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleList") }}</div> -->

      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <label id="lbl-user-name">{{ userFullName }}</label>

      <v-btn text id="btn-logout" class="menu-btn" @click="logout">
        <img src="../assets/img/logout_menu_icon.png" />ログアウト</v-btn
      >
    </v-app-bar>
    <!-- <v-sheet class="overflow-hidden"> -->
    <!-- <Loading /> -->
    <!-- <v-container fluid id="sub-bar">
      <v-row>
        <v-col>
          <v-btn text @click="showMenu">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
          <div id="lbl-screen-name">{{ $t("label.lbl_arrivalScheduleList") }}</div>
        </v-col>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-col class="mr-n16">
          <label id="lbl-user-name">{{ userFullName }}</label>

          <v-btn text id="btn-logout" class="menu-btn" @click="logout">
            <img src="../assets/img/logout_menu_icon.png" />ログアウト</v-btn
          ></v-col
        >
      </v-row>
    </v-container> -->
    <v-navigation-drawer absolute temporary v-model="openMenu" style="width: 25%">
      <sideMenu></sideMenu>
    </v-navigation-drawer>
    <!-- </v-sheet> -->
  </div>
</template>
<script>
import sideMenu from "@/components/Menu";

// import { i18n } from "../lang/lang";
export default {
  name: "NavBar",
  components: {
    sideMenu,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
    tittle: { default: null },
  },
  data: () => ({
    userFullName: sessionStorage.getItem("userInfo.userName"),
    // メニュー
    openMenu: null,
    familyName: "",
    givenName: "",
    userId: "",
    roles: "",
    autoRefreshEventIds: null,
  }),
  methods: {
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    init() {
      Promise.all([])
        .then(() => {
          // 自動更新を設定します。
          //this.autoRefreshEventIds = setInterval(this.getCredentials, 1000 * 60 * 59);
        })
        .catch((ex) => {
          console.error("init error", ex);
        })
        .finally(() => {});
    },

    /**
     * ログアウトボタンのバインド変数を設定します。
     */
    logout() {
      this.$httpClient.logout();
      console.info("logout");
      var url = "/";
      // 警告灯フラグが存在する場合、ログイン画面のパラメータに追加
      if (sessionStorage.getItem("pt") == "1") {
        url = url + "?pt=1";
      }
      this.$router.push(url);
    },
    getCredentials() {
      this.$httpClient.getApiGatewayCredentials().then(() => {});
    },
  },
  // computed: {
  // },
  watch: {},
  mounted() {
    this.init();
    /*
    this.userId = sessionStorage.getItem("username");
    this.roles = sessionStorage.getItem("role");
    this.$httpClient
      .getUserAttribute(this.userId)
      .then((result) => {
        this.familyName = result.get("family_name");
        this.givenName = result.get("given_name");
      })
      .catch((error) => {
        console.error("mounted error", error);
      });
      */
  },
  destroyed() {
    // 自動更新イベントを開放します。
    /*
    if (this.autoRefreshEventIds) {
      clearInterval(this.autoRefreshEventIds);
    }
    */
  },
};
</script>
<style>
@import "../css/style.css";
</style>
<style lang="scss" scoped>
$menu-fontsize: large;

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
}
#lbl-system-name {
  font-family: Meiryo;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  font-size: $menu-fontsize !important;
  font-weight: bold;
}
#lbl-user-name {
  font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
</style>
